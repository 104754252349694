<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">

        <color-logo />

        <h2 class="brand-text text-primary ml-1">
          {{ siteTitle }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('Enter Code?') }} 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('Check your email for confirmation code') }}
          </b-card-text>

          <!-- form -->
          <b-form
            class="auth-forgot-password-form mt-2"
          >
            <b-form-group
              label-for="forgot-password-email"
            >
              <vx-input
                v-model="code"
                :errors="errors.code"
                :label="$t('check your email and Enter Your Code')"
                type="text"
              />
            </b-form-group>

            <b-button
              variant="primary"
              block
              @click="formSubmit"
            >
              {{ $t('Send reset link') }}
            </b-button>
          </b-form>

          <p class="text-center mt-2">
            <b-link :to="{name:'login'}">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BButton,
} from 'bootstrap-vue'
import store from '@/store/index'
import ColorLogo from '@/layouts/components/ColorLogo.vue'

export default {
  components: {
    ColorLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BCardTitle,
    BCardText,
  },
  data() {
    return {
      code: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      errors: [],
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    siteTitle() {
      return this.$store.getters['app/title']
    },
  },
  created() {
  },
  methods: {
    formSubmit() {
      this.$store.dispatch('auth/checkCode', { code: this.code, email: this.$route.params.email }).then(response => {
        if (response.data.status === true) {
          this.$router.push({ name: 'reset_password', params: { email: this.$route.params.email, code: this.code } })
        } else {
          this.$router.push({ name: 'not-authorized' })
        }
      }).catch(error => {
        if (error.response.data.errors) this.errors = error.response.data.errors
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
